import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import TwmButton from "../components/twm-button"

import Header from "../components/header"
import Section from "../components/section"

const NotFoundPage = props => (
  <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
    <SEO title="404: Not found" />
    <BackgroundImage
      fluid={props.data.notFoundImage.childImageSharp.fluid}
      alt="A man holding his two children whilst a third one plays"
    >
      <Header headerText="Page Not Found"></Header>
    </BackgroundImage>
    <Section>
      <p>You just hit a route that doesn&#39;t exist...</p>
      Go to the{" "}
      <TwmButton toRef={props.pageContext.firm + "/"}>home page</TwmButton>
    </Section>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    notFoundImage: file(relativePath: { eq: "about-you.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
